import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SliceZone from "../components/sliceZone"
import { Helmet } from "react-helmet"

export const query = graphql`
  query PageQuery($id: String) {
    prismic {
      allPages(id: $id) {
        edges {
          node {
            page_description
            page_title
            _meta {
              id
              uid
            }
            body {
              ... on PRISMIC_PageBodyRich_text {
                type
                label
                primary {
                  rich_text_content
                  text_align
                }
              }
              ... on PRISMIC_PageBodyVideo {
                type
                primary {
                  title
                  video_embed
                }
              }
              ... on PRISMIC_PageBodyText_and_image {
                type
                fields {
                  image
                  image_align
                  text
                }
                primary {
                  title
                }
              }
              ... on PRISMIC_PageBodyCall_to_action_grid {
                type
                label
                fields {
                  button_destenation {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC_Contact_page {
                      _meta {
                        uid
                      }
                    }
                  }
                  button_label
                  content
                  cta_title
                  featured_image
                }
                primary {
                  section_title
                }
              }
              ... on PRISMIC_PageBodyHero {
                type
                fields {
                  cta_style
                  cta__label
                  cta_link {
                    ... on PRISMIC_Contact_page {
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                      }
                    }
                  }
                }
                primary {
                  background_image
                  hero_content
                  hero_title
                }
              }
            }
          }
        }
      }
    }
  }
`

const Page = props => {
  const prismicConetent = props.data.prismic.allPages.edges[0]
  if (!prismicConetent) return null

  const document = prismicConetent.node

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{document.page_title}</title>
        <meta name="description" content={document.page_description} />
      </Helmet>

      <Layout>
        <SliceZone body={document.body} />
      </Layout>
    </>
  )
}

export default Page
